<template>
    <div>
        <ConfigurationsList
            v-if="userHasConfigurationPermission"
            class="mt-4 mb-5"
            data-cy="configurations"
            :exclude="configurationsToExclude"
            :readonly="!userCanManageConfigurations"
        />

        <KCForbidden
            v-else
            class="mt-3"
            data-cy="forbidden"
        />
    </div>
</template>

<script>
    import ConfigurationsList from '@imt/vue-configuration/src/components/Configurations.vue';
    import KCForbidden from '@imt/vue-kit-car/src/components/errors/Forbidden.vue';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';

    export default {
        name: 'BRConfigurations',
        components: {
            ConfigurationsList,
            KCForbidden,
        },
        mixins: [authMixin],
        computed: {
            configurationsToExclude() {
                return [];
            },
            userHasConfigurationPermission() {
                return this.userCan('configuration_access');
            },
            userCanManageConfigurations() {
                return this.userCan('configuration_manage');
            },
        },
    };
</script>
